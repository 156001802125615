<template lang="pug">
div#home
  div.info.flex.padding._5_10.txt.gray
    img(src="@/assets/img/info.png")
    div.grow
      p 今日累计积分{{inf.user_day_credit}}
      p(v-if="team_id / 1") {{inf.team_rank}}
      p(v-else): router-link(to="/me?box=team") {{inf.team_rank}}
  div.home-list-box
    div.tit: span.bg.red 最新发布
    listBox(:objs="articles")/
</template>
<script>
import listBox from '@/components/article/list'
import user from '@/assets/script/cache'
export default {
  components: {listBox},
  data(){return {
    articles: [],
    inf: {},
    team_id: 0
  }},
  mounted(){
    this.$req.home((res)=>{
      let {team_rank, user_day_credit, articleList} = res
      this.articles = articleList
      this.inf = {team_rank, user_day_credit}
      this.team_id = user.get('team_id')
    })
  }
}
</script>
<style scoped lang="less">
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
#home .info{
  line-height: 1.6;
  .__rem(10);
  img{
    height: $width;
    .__rem(28, width);
    .__rem(10, margin-right);
  }
  .__border(bottom, #ddd, solid, 5);
}
.home-list-box{
  .tit span{
    .__rem(8);
    .__spacing(padding, 3, 15);
  }
}
</style>